import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import Tilt from "src/components/tilt"
import Carbonate_disc_search from "src/components/carbonate_disc_search"
import BelowFold from "src/components/btf_carbonate"

const footerData = import("./data/btfData.json");
const TiltData = import("./data/data.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
    <title>Find Manuals to Read & Print</title>
    <style type="text/css">{`
    #disclaimers-module--disclaimers {
      margin-top:auto;
      text-align:center;
      font-size:12px;
      line-height:12px;
      padding:0;
      margin:0;
      color:#777;
      width:400px;
    }
    .btf_carbonate-module--btfCarb button.btf_carbonate-module--cws_dl {
    padding:20px 20px;
    background:#3b82f6;
    color:#fff;
    font-size:40px;
    font-weight:700;
    border:none;
    border-radius:10px;
    }

    .btf_carbonate-module--btfCarb button.btf_carbonate-module--cws_dl:hover {
    background:#295bac;
    }



   footer {
    font-family: 'Roboto', sans-serif !important;
    display: block;

    position: relative;
    color: #333;
    padding: 0;
    width: 100%;
    margin:0;
  }
  footer ul {
    position: relative;
    list-style: none;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    color: #333;
    font-size: 12px;
    padding: 12px 0;
  }
  footer ul li {
    padding: 0 4px 0 0;
  }
  footer ul li:last-child {
    padding: 0;
  }
  footer ul li:after {
    content: '|';
    padding: 0 0 0 4px;
  }
  footer ul li:nth-child(6):after, footer ul li:last-child:after {
    content: none;
    padding: 0;
  }
  footer ul a {
    color: #333;
  }
  
    `}
    </style>
    </Helmet>
    <section>
      <Tilt data={TiltData}><Carbonate_disc_search></Carbonate_disc_search></Tilt>
      <BelowFold data={footerData}><Carbonate_disc_search></Carbonate_disc_search></BelowFold>
      </section>
    </HomepageLayout>
  )
  }
